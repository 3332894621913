<template>
  <v-container fluid class="fill-height blue-grey darken-3 ma-0">
    <v-row class="justify-center fill-height">
      <v-col lg="4">
        <v-video-call class="flex-fill fill-height" @connecting="$val => { connecting = $val }" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VVideoCall from './components/vVideoCall'

export default {
  components: { VVideoCall },
  data() {
    return {
      active: true,
      connecting: false
    }
  },
  beforeRouteLeave(to, from, next) {
    this.active = false
    this.$nextTick(next)
  }
}
</script>
