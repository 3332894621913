<template>
  <v-app class="blue darken-3">
    <v-main>
      <v-container fluid fill-height class="align-stretch flex-nowrap pa-0">
        <div class="login-background hidden-xs-only pa-5">
          <h2 class="mb-16">ВидеоВиджет | вход</h2>
          <p class="mb-16">&nbsp;</p>
          <div class="subtitle-2">Что такое ВидеоВиджет?</div>
          <p class="pa-3 body-1">
            Это инструмент повышении конверсии сайта. Консультируйте клиентов в режиме видео-связи прямо из браузера.
          </p>
          <p class="pt-12 body-2">
            <a href="https://videowidget.ru/?utm_source=app.videowidget.ru">Подробнее о ВидеоВиджет</a>
          </p>
        </div>
        <div class="flex" style="min-width: 360px">
          <v-slide-x-transition mode="out-in">
            <router-view />
          </v-slide-x-transition>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
}
</script>

<style>
  .login-background {
    /*background-color: #eceff1;*/
    background-color: #1976d2;
    color: white;
    /*background-image: url(./../assets/img/loginbg1.jpg);*/
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    flex-basis: 400px;
    max-width: 400px;
  }
  .login-background a {
    color: white;
  }
</style>
