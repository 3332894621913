<template>
  <v-container>
    <v-row>
      <v-col class="text-right caption">
        Уже есть учетная запись? <router-link to="/login">Войти</router-link>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="auto" class="pt-10">
        <apollo-mutation
          :mutation="require('@cpanel/graphql/auth-register.mutation.gql')"
          :variables="{ data: regData }"
          @done="onSignUp"
        >
          <template v-slot="{ mutate, loading, error }">
            <v-card width="360" class="elevation-0">
              <v-card-text>
                <div class="layout column align-center">
                  <h1 class="flex my-4 pb-4 primary--text">Регистрация</h1>
                  <v-expand-transition>
                    <p v-if="error" class="red--text text-center pb-5">
                      <template v-if="error.message.indexOf('ALREADY_REGISTERED') >= 0">
                        Такой аккаунт уже зарегистрирован. Выполните
                        <router-link to="/login">вход</router-link> или укажите другой адрес электронной почты.
                      </template>
                      <template v-else>
                        Сожалеем, но что-то пошло не так. Попробуйте повторить позже или обратитесь в службу поддержки.
                      </template>
                    </p>
                  </v-expand-transition>
                </div>
                <v-form ref="form" v-model="valid">
                  <v-text-field
                    v-model="name"
                    :autofocus="name === null"
                    :disabled="loading"
                    append-icon="person"
                    label="Ваше имя"
                    :rules="[rules.required, rules.username]"
                    @keyup.enter="mutate()"
                  />
                  <v-text-field
                    v-model="email"
                    :disabled="loading"
                    append-icon="email"
                    label="Email"
                    :rules="[rules.required, rules.email]"
                    @keyup.enter="mutate()"
                  />
                  <v-text-field
                    v-model="password"
                    :disabled="loading"
                    :type="hidePassword ? 'password' : 'text'"
                    :append-icon="hidePassword ? 'visibility_off' : 'visibility'"
                    label="Придумайте пароль"
                    :rules="[rules.required, rules.password]"
                    @click:append="hidePassword = !hidePassword"
                    @keyup.enter="mutate()"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions class="pt-5 d-block">
                <v-btn large block color="primary" :loading="loading" :disabled="!valid || loading" @click="mutate()">Создать аккаунт</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </apollo-mutation>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from '@cpanel/includes/form-rules'

export default {
  data() {
    return {
      valid: false,
      name: null,
      email: null,
      password: null,
      hidePassword: true,
      rules
    }
  },
  computed: {
    regData() {
      return {
        name: this.name,
        email: this.email,
        password: this.password
      }
    }
  },
  mounted() {
    this.email = this.$route.query.email || null
  },
  methods: {
    async onSignUp(response) {
      const authData = response.data.register
      await this.$store.dispatch('user/login', authData)
      this.$toast.success('Успешный вход')
      this.$router.replace('/')
    }
  }
}
</script>

<style scoped>

</style>
