<template>
  <v-container>
    <v-row>
      <v-col class="text-right caption">
        Нужна учетная запись? <router-link to="/register">Зарегистрироваться</router-link>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="auto" class="pt-10">
        <apollo-mutation
          :mutation="gqlLoginMutation"
          :variables="{ auth: { email, password } }"
          @error="onError"
          @done="onSignIn"
        >
          <template v-slot="{ mutate, loading, error }">
            <v-card width="360" class="elevation-0">
              <v-card-text>
                <div class="layout column align-center">
                  <h1 class="flex my-4 pb-4 primary--text">Вход</h1>
                  <v-expand-transition>
                    <p v-if="error" class="red--text text-center pb-5">
                      Неверный email или пароль. Вы можете
                      <router-link :to="{ path: '/forgot-password', query: { email }}">восстановить пароль</router-link> или
                      <router-link :to="{ path: '/register', query: { email }}">зарегистрироваться</router-link>.
                    </p>
                  </v-expand-transition>
                </div>
                <v-form ref="form" v-model="valid">
                  <v-text-field
                    v-model="email"
                    :autofocus="email === null"
                    :disabled="loading"
                    append-icon="person"
                    label="Email"
                    :rules="[rules.required, rules.email]"
                    @keyup.enter="mutate()"
                  />
                  <v-text-field
                    ref="password"
                    v-model="password"
                    :autofocus="email !== null"
                    :disabled="loading"
                    :type="hidePassword ? 'password' : 'text'"
                    :append-icon="hidePassword ? 'visibility_off' : 'visibility'"
                    label="Пароль"
                    :rules="[rules.required]"
                    @click:append="hidePassword = !hidePassword"
                    @keyup.enter="mutate()"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions class="pt-5 d-block">
                <div>
                  <v-btn large block color="primary" :loading="loading" :disabled="!valid || loading" @click="mutate()">Войти</v-btn>
                </div>
                <div class="pa-10">
                  <v-btn text small class="grey--text" block :disabled="loading" :to="{ path: '/forgot-password', query: { email }}">забыли пароль?</v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </template>
        </apollo-mutation>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import gqlLoginMutation from '@cpanel/graphql/auth-login.mutation.gql'
import rules from '@cpanel/includes/form-rules'

export default {
  data() {
    return {
      valid: false,
      email: null,
      password: null,
      hidePassword: true,
      rules,
      gqlLoginMutation
    }
  },
  created() {
    const user = this.$store.getters['user/lastLogonUser']
    this.email = user ? user.email : null
  },
  methods: {
    async onSignIn(response) {
      const authData = response.data.login
      await this.$store.dispatch('user/login', authData)
      this.$toast.success('Успешный вход')
      this.$router.replace('/')
    },

    onError() {
      this.password = ''
      setTimeout(() => { this.$refs.password.$el.querySelector('input').focus() }, 100)
    }
  }
}
</script>

<style scoped>
</style>
