<template>
  <div class="d-flex justify-center" style="position: relative;">
    <div v-if="connected && hasAudio && !hasVideo" class="align-self-center white--text text-center" style="position: absolute;">
      <div class="subtitle-1">на связи</div>
      <div class="headline">{{ caller.nickname }}</div>
      <div class="py-5">
        <v-icon style="font-size: 128px; color: white;">
          mdi-video-off-outline
        </v-icon>
      </div>
    </div>
    <div v-if="connecting" class="align-self-center white--text headline" style="position: absolute;">
      <div class="py-5 text-center">
        <v-icon style="font-size: 96px; color: white;">
          mdi-timer-sand-empty
        </v-icon>
      </div>
      <span>Ожидание соединения...</span>
    </div>
    <div v-if="!connecting && !connected" class="align-self-center white--text headline" style="position: absolute;">
      <div class="py-5 text-center">
        <v-icon style="font-size: 128px; color: white;">
          mdi-phone-off-outline
        </v-icon>
      </div>
      <span>Вызов завершен</span>
    </div>
    <video ref="remoteVideo" class="d-flex" style="max-height: 100%; max-width: 100%; width: auto; height: 100%;" autoplay />
    <video ref="localVideo" style="position: absolute; right: 10px; height: 120px; width: 100px;" autoplay muted />
    <div
      class="d-flex justify-space-around align-center mb-2"
      style="position: absolute; bottom: 0; width: 100%"
    >
      <v-btn
        v-if="false"
        fab
        color="purple"
        bottom
        dark
        x-large
        :disabled="!connected || connecting"
        @click="switchMute"
      >
        <v-icon>{{ mute ? 'mic_off' : 'mic' }}</v-icon>
      </v-btn>
      <v-btn
        v-if="false"
        fab
        color="purple"
        bottom
        dark
        :disabled="!connected || connecting"
        x-large
        @click="flipCamera"
      >
        <v-icon>flip_camera_android</v-icon>
      </v-btn>
      <v-btn
        fab
        color="purple"
        bottom
        dark
        x-large
        @click="hangup"
      >
        <v-icon>phone_disabled</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      connected: false,
      connecting: true,
      mute: false,
      connectionTimer: null
    }
  },
  computed: {
    localStream() {
      return this.$store.getters['videochat/localStream']
    },
    remoteStream() {
      return this.$store.getters['videochat/remoteStream']
    },
    hasAudio() {
      return this.remoteStream ? (this.remoteStream.getAudioTracks().length > 0) : false
    },
    hasVideo() {
      return this.remoteStream ? (this.remoteStream.getVideoTracks().length > 0) : false
    },
    caller() {
      return this.$store.getters['videochat/caller']
    }
  },
  watch: {
    caller(val) {
      if (!val) {
        this.connecting = false
        this.connected = false
        this.goHome()
      }
    },
    hasVideo() {
      this.checkConnected()
    },
    hasAudio() {
      this.checkConnected()
    },
    remoteStream(stream) {
      this.setRemoteStream(stream)
    },
    localStream(stream) {
      this.setLocalStream(stream)
    }
  },
  mounted() {
    this.setLocalStream(this.localStream)
    this.setRemoteStream(this.remoteStream)
    this.checkConnected()
  },
  async beforeDestroy() {
    await this.$store.dispatch('videochat/hangup')
  },
  methods: {
    goHome() {
      this.$nextTick(() => {
        (this.$router.currentRoute.name === 'conference-history') || this.$router.push({ name: 'conference-history' })
      })
    },
    async flipCamera() {
      // nop
    },
    async hangup() {
      // Просто уходим со страницы. Реальный hangup сработает в триггере компонента beforeDestroy
      this.goHome()
    },
    switchMute() {
      this.mute = !this.mute
    },
    setRemoteStream(stream) {
      this.$refs['remoteVideo'].srcObject = stream
    },
    setLocalStream(stream) {
      this.$refs['localVideo'].srcObject = stream
    },
    checkConnected(waitTimeout = 30000) {
      if (this.hasVideo || this.hasAudio) {
        this.connecting = false
        this.connected = true
      } else {
        if (this.connectionTimer) clearTimeout(this.connectionTimer)
        this.connecting = true
        this.connectionTimer = setTimeout(() => {
          this.connecting = false
          this.connected = this.hasVideo || this.hasAudio
          this.connectionTimer = null
          if (!this.connected) this.goHome()
        }, waitTimeout)
      }
    }
  }
}
</script>

<style scoped>

</style>
