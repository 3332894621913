<template>
  <v-container>
    <v-row v-if="!completed">
      <v-col class="text-right caption">
        Нужна учетная запись? <router-link to="/register">Зарегистрироваться</router-link>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col v-if="!completed" cols="auto" class="pt-10">
        <apollo-mutation
          :mutation="require('@cpanel/graphql/auth-forgot-password.mutation.gql')"
          :variables="{ data: { email } }"
          @done="onDone"
        >
          <template v-slot="{ mutate, loading, error }">
            <v-card width="360" class="elevation-0">
              <v-card-text>
                <div class="layout column align-center">
                  <h1 class="flex my-4 pb-4 primary--text">Забыли пароль?</h1>
                  <v-expand-transition>
                    <p v-if="error" class="red--text text-center pb-5">
                      Сожалеем, но что-то пошло не так. Попробуйте повторить позже или обратитесь в службу поддержки.
                    </p>
                  </v-expand-transition>
                  <p v-if="!error" class="text-center pb-5">
                    Не волнуйтесь. Просто введите ваш email, и мы отправим инструкцию по восстановлению доступа.
                  </p>
                </div>
                <v-form ref="form" v-model="valid">
                  <v-text-field
                    v-model="email"
                    :disabled="loading"
                    append-icon="email"
                    label="Email"
                    :rules="[rules.required, rules.email]"
                    @keyup.enter="mutate()"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions class="pt-5 d-block">
                <v-btn large block color="primary" :loading="loading" :disabled="!valid || loading" @click="mutate()">Прислать инструкцию</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </apollo-mutation>
      </v-col>
      <v-col v-if="completed" cols="auto" class="pt-10">
        <v-card width="360" class="elevation-0">
          <v-card-title class="pt-10 justify-center">Помощь уже в пути!</v-card-title>
          <v-card-text class="text-center pt-2 body-1">
            Проверьте вашу почту. Мы отправили инструкцию по сбросу пароля на адрес
            <span class="subtitle-2">{{ email }}</span>.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <v-btn small text @click="$router.go(-1)">
          <v-icon size="16">mdi-arrow-left</v-icon>
          назад
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from '@cpanel/includes/form-rules'

export default {
  data() {
    return {
      valid: false,
      email: null,
      rules,
      completed: false
    }
  },
  mounted() {
    this.completed = false
    this.email = this.$route.query.email || null
  },
  methods: {
    onDone() {
      this.completed = true
    }
  }
}
</script>

<style scoped>

</style>
