export default {
  required: value => !!value || 'обязательное поле',
  email: value => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || 'Неверный email'
  },
  username: v => {
    if (v && v.length < 2) return 'не менее 2 символов'
    if (v && v.length > 64) return 'не более 64 символов'
    return true
  },
  password: v => {
    if (v && v.length < 6) return 'не менее 6 символов'
    if (v && v.length > 64) return 'не более 64 символов'
    return true
  },
  placename: v => {
    if (v && v.length > 64) return 'не более 64 символов'
    return true
  }
}
